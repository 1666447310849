import styled from 'styled-components';

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	z-index: 999;
	display: grid;
	place-items: center;
	pointer-events: none;
`;

const Spinner = styled.div`
	width: 5rem;
	height: 5rem;
	color: var(--Gold);
`;

const LoadingOverlay = () => {
	return (
		<Overlay>
			<Spinner className='spinner-border'>
				<span className='sr-only'>Loading...</span>
			</Spinner>
		</Overlay>
	);
};

export default LoadingOverlay;
