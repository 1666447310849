import styled from 'styled-components';
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from 'components/pages/NotFound';
import Plans from 'components/Plans/Plans/Plans';
import Plan from 'components/Plans/Plan/Plan';
import New from 'components/Plans/New/New';
import Edit from 'components/Plans/Edit/Edit';
import Briefs from 'components/Briefs/Briefs';
import Brief from 'components/Briefs/Brief/Brief';
import Designer from 'components/Edit/Designer/Designer';
import Collections from 'components/Collections/New/New';
import Admin from 'components/Admin/Admin';
import { useLogin } from 'utils/UserContext';
import Footer from 'components/layout/Footer';
import MessageBar from 'components/shared/MessageBar';

const Component = styled.main`
	background: var(--light3);
	grid-area: main;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: minmax(0, 1fr);
	overflow-x: auto;
`;

const Wrapper = styled.div`
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding: 3rem;

	@media (max-width: 992px) {
		padding: 2rem;
	}

	@media (max-width: 576px) {
		padding: 1rem;
	}
`;

const Main = () => {
	// Hooks
	const { user } = useLogin();
	const { isAdmin } = { ...user };

	return (
		<Component>
			<MessageBar />
			<Wrapper>
				<Routes>
					<Route path='/' element={<Navigate to='plans' />} />
					<Route path='admin' element={isAdmin ? <Admin /> : <Navigate to='/' />}></Route>
					<Route path='brief'>
						<Route path=':id' element={<Brief />} />
					</Route>
					<Route path='briefs' element={<Briefs />} />
					<Route path='collections'>
						<Route path='new' element={<Collections />} />
					</Route>
					<Route path='edit'>
						<Route path='designer' element={<Designer />} />
						<Route path=':id' element={<Edit />} />
					</Route>
					<Route path='plan'>
						<Route path=':id' element={<Plan />} />
						<Route path='new' element={<New />} />
					</Route>
					<Route path='plans' element={<Plans />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Wrapper>
			<Footer />
		</Component>
	);
};

export default Main;
