import { tidyLocalDateTime } from 'utils/Tidy';
import styled from 'styled-components';

import dayjs from 'dayjs';

const Highlight = styled.span`
	color: var(--Gold);
`;

const Anchor = styled.a`
	text-decoration: none !important;
	border-bottom: 1px dotted;

	&:hover,
	&:focus-visible {
		border-bottom: 1px solid;
	}
`;

const WhatWeAreDoing = ({ plan }) => {
	// Destructure props
	const { stage, assets_url, duda_site_id, preview_site_url, client_access_url, site_type, events, creative_brief } = { ...plan };
	const { website_template } = { ...creative_brief };

	// Events
	const kickOffCall = events.length > 0 && events.find(event => event.name === 'Kick Off Call');
	const revisionsCall = events && events.length > 0 && events.find(event => event.name === 'Revisions Call' && dayjs().isBefore(dayjs(event.date)));

	// Preview Link
	const preview_link = duda_site_id ? `https://dashboard.appfoliowebsites.com/preview/${duda_site_id}` : preview_site_url ? preview_site_url : null;

	// Conditional render
	switch (stage) {
		case 'Welcome':
			return !kickOffCall ? (
				<>
					<p>Please ⭐ bookmark ⭐ this page as we will be referring to it frequently over the next few weeks.</p>
					<p>To get started, click the button below to schedule a kickoff call with me.</p>
					<p>We will go over the build process and timeline, and review items that you will need to provide so that I can start working on your new website.</p>
				</>
			) : (
				<>
					<h6 className='font-semibold'>Thank you for scheduling a kickoff call! 🥳 </h6>
					<p>
						I look forward to speaking with you on <Highlight>{tidyLocalDateTime(kickOffCall.date)}</Highlight>
					</p>
					<h6 className='font-semibold'>Feeling eager to get started? 💪</h6>
					<p>Here are some things you can do to help speed up the process:</p>
					<ol>
						<li className='mb-3'>
							<strong>Design choice.</strong>{' '}
							<Anchor href={`https://www.appfolioimwebsites.com/${site_type.toLowerCase()}-templates`} target='_blank' rel='noopener noreferrer'>
								Take a look at our design templates
							</Anchor>{' '}
							and note which ones catch your eye. We’ll use these as starting points when building your website.
						</li>
						<li>
							<strong>Gather content.</strong> On our kickoff call, we’ll be asking for these items to help us build your site. You’re welcome to{' '}
							{assets_url ? (
								<Anchor href={assets_url} target='_blank' rel='noopener noreferrer'>
									start uploading these to your dedicated Dropbox folder!
								</Anchor>
							) : (
								'start preparing these to have ready for our call.'
							)}
						</li>
					</ol>
				</>
			);

		case 'Collect':
			return (
				<>
					<p>As discussed, I need items from you before I can start building your website. The required assets are listed in the checklist below.</p>
					{!website_template && (
						<p>
							<Anchor href={`https://www.appfolioimwebsites.com/${site_type.toLowerCase()}-templates`} target='_blank' rel='noopener noreferrer'>
								Take a look at our design templates
							</Anchor>{' '}
							and note which ones catch your eye. We’ll use these as starting points when building your website.
						</p>
					)}
					<p>If you are unsure what else you need to provide, please contact me directly.</p>
				</>
			);

		case 'Build':
			return (
				<>
					<p>I am now building your website based on the template you have chosen and design elements discussed during the kick-off call.</p>
					<p>Once the build is completed, I will email you a preview site link no later than the due date listed below.</p>
				</>
			);

		case 'Review':
			return !revisionsCall ? (
				<>
					<p>Your preview site is ready! 🎁</p>
					<p>
						I now need your help to finish the website project and go live!{' '}
						{preview_link && (
							<Anchor href={preview_link} target='_blank' rel='noopener noreferrer'>
								Take a look at your preview site.
							</Anchor>
						)}
					</p>
					{client_access_url && (
						<>
							<p>
								I have enabled the site comments feature so that you can easily provide feedback.{' '}
								<Anchor href={client_access_url} target='_blank' rel='noopener noreferrer'>
									Click here to begin your revisions.
								</Anchor>{' '}
								If you have any questions about site comments please contact me directly.
							</p>
							<p>Once you have reviewed the site and provided feedback, please schedule a call with me by clicking the link below. We’ll go over your feedback and edits, and get ready to set the site live!</p>
						</>
					)}
				</>
			) : (
				<>
					<h6 className='font-semibold'>Thank you for scheduling our next revisions call! 🥳 </h6>
					<p>
						I look forward to speaking with you on <Highlight>{tidyLocalDateTime(revisionsCall.date)}</Highlight>
					</p>
				</>
			);

		case 'Live':
			return <p>Nothing, your site is live!</p>;

		default:
			return null;
	}
};

export default WhatWeAreDoing;
