import { useState } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const Wrapper = styled.div`
	background: var(--lightest);
	border-bottom: 1px solid var(--bs-light);
	margin: 0.5rem 0;
	padding: 0 0.5rem 0.5rem;

	& .button-icon:hover {
		cursor: pointer;
	}
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const Buttons = styled.div`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	flex: 0 0 auto;
`;

const SingleAsset = ({ asset, index, handleToggle, handleDelete, handleEdit }) => {
	// Component state
	const [isEditing, setIsEditing] = useState(false);
	const [assetName, setAssetName] = useState(asset.name);

	// Handle edit asset
	const editAsset = (id, name) => {
		handleEdit(id, name);
		setIsEditing(false);
	};

	// Cancel edit asset
	const cancelEdit = () => {
		setAssetName(asset.name);
		setIsEditing(false);
	};

	return (
		<Draggable draggableId={asset._id} index={index}>
			{provided => (
				<Wrapper className='d-flex align-items-start' ref={provided.innerRef} {...provided.draggableProps}>
					{!isEditing ? (
						<Item>
							<FormCheck custom id={asset._id} className='me-2'>
								<FormCheck.Input type='checkbox' defaultChecked={asset.complete} className='me-2' onClick={() => handleToggle(asset._id)} />
								<FormCheck.Label style={{ textDecoration: asset.complete ? 'line-through' : 'none' }}>{asset.name}</FormCheck.Label>
							</FormCheck>
							<Buttons>
								<i className='button-icon fas fa-pen text-primary' onClick={() => setIsEditing(true)}></i>
								<i className='button-icon fas fa-trash-alt text-danger' onClick={() => handleDelete(asset._id)}></i>
								<i className='fas fa-grip-lines text-muted' {...provided.dragHandleProps}></i>
							</Buttons>
						</Item>
					) : (
						<>
							<Form.Control id={asset._id} as='textarea' value={assetName} onChange={e => setAssetName(e.target.value)} className='border-0 shadow-sm me-3' />
							<Buttons>
								<i className='button-icon fas fa-check text-success' onClick={() => editAsset(asset._id, assetName)}></i>
								<i className='button-icon fas fa-times text-danger' onClick={() => cancelEdit()}></i>
								<i className='fas fa-ellipsis-v text-muted'></i>
							</Buttons>
						</>
					)}
				</Wrapper>
			)}
		</Draggable>
	);
};

export default SingleAsset;
