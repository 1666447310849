import { Subsection, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyParagraphs } from 'utils/Tidy';

const GeneralContent = ({ creative_brief }) => {
	// Destructure props
	const { project_notes, website_goals, who_are_we_marketing_the_website_to, what_kind_of_deal_structures_do_you_manage, how_do_you_normally_raise_funds_attract_investors, how_do_you_showcase_your_past_projects_what_is_important_to_showcase } = { ...creative_brief };

	const exists = project_notes || website_goals || who_are_we_marketing_the_website_to.length > 0 || what_kind_of_deal_structures_do_you_manage.length > 0 || how_do_you_normally_raise_funds_attract_investors || how_do_you_showcase_your_past_projects_what_is_important_to_showcase ? true : false;

	return (
		exists && (
			<Subsection>
				<h5 className='text-xl font-semibold'>General Content</h5>
				<Grid>
					{project_notes && (
						<Panel>
							<Name>Project Notes</Name>
							<Value>{tidyParagraphs(project_notes)}</Value>
						</Panel>
					)}
					{website_goals && (
						<Panel>
							<Name>Website Goals</Name>
							<Value>{tidyParagraphs(website_goals)}</Value>
						</Panel>
					)}
					{who_are_we_marketing_the_website_to && (
						<Panel>
							<Name>Who are we marketing the website to?</Name>
							<Value>
								<ul>
									{who_are_we_marketing_the_website_to.map((item, i) => (
										<li key={i}>{item}</li>
									))}
								</ul>
							</Value>
						</Panel>
					)}
					{what_kind_of_deal_structures_do_you_manage && (
						<Panel>
							<Name>What kind of deal structures do you manage?</Name>
							<Value>
								<ul>
									{what_kind_of_deal_structures_do_you_manage.map((item, i) => (
										<li key={i}>{item}</li>
									))}
								</ul>
							</Value>
						</Panel>
					)}
					{how_do_you_normally_raise_funds_attract_investors && (
						<Panel>
							<Name>How do you normally raise funds/attract investors?</Name>
							<Value>{tidyParagraphs(how_do_you_normally_raise_funds_attract_investors)}</Value>
						</Panel>
					)}
					{how_do_you_showcase_your_past_projects_what_is_important_to_showcase && (
						<Panel>
							<Name>How do you showcase your past projects? What is important to showcase?</Name>
							<Value>{tidyParagraphs(how_do_you_showcase_your_past_projects_what_is_important_to_showcase)}</Value>
						</Panel>
					)}
				</Grid>
			</Subsection>
		)
	);
};

export default GeneralContent;
