import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import MainWrapper from 'components/shared/MainWrapper';
import styled from 'styled-components';
import { calculateDueDate } from 'utils/Stages';

import { getJwt } from 'utils/Auth';
import { tidyDateWords, tidyUrl, tidyLocalDateTime } from 'utils/Tidy';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PlanStatus from 'components/shared/PlanStatus';
import Timeline from './Timeline';
import WhatWeAreDoing from './WhatWeAreDoing';
import WhatWeNeed from './WhatWeNeed';
import DueDate from './DueDate';
import Designer from './Designer';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';

const Component = styled.div`
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding: 1rem;
`;

const Heading = styled.div`
	margin-bottom: 2rem;
`;

const PanelWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 1rem;
	margin-top: 1rem;
`;

const Arrow = styled.div`
	height: 0;
	width: 0;
	border-top: 2rem solid transparent;
	border-bottom: 2rem solid transparent;
	border-right: 3rem solid #fff;
	position: absolute;
	top: 2rem;
	left: -3rem;
	z-index: 1;

	@media (max-width: 767px) {
		border-left: 2rem solid transparent;
		border-right: 2rem solid transparent;
		border-bottom: 3rem solid #fff;
		top: -4.5rem;
		left: 50%;
		transform: translateX(-50%);
	}
`;

const Panel = styled.div`
	background: white;
	padding: 1.5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	position: relative;
	word-break: break-all;

	p:last-child {
		margin: 0;
	}
`;

const Name = styled.div`
	margin: 0;
	font-weight: bold;
	font-size: 1rem;
`;

const Value = styled.div`
	font-size: 1.25rem;
	margin: 0;
	color: var(--bs-secondary) !important;
`;

const Event = styled.div`
	line-height: 1.25;
	margin: 0.25rem 0;

	p {
		margin: 0;
	}
`;

const LowerWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	align-items: start;
	justify-content: center;
	gap: 2rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const View = () => {
	// Component state
	const [mounted, setMounted] = useState(false);

	// Hooks
	const { id } = useParams();
	const { data: plan } = useSWR(mounted ? `/api/v1/plans/${id}` : null, `/api/v1/plans/${id}`);
	const { data: designer } = useSWR(() => `/api/v1/users/email/${plan.appfolio_onboarding_contact}`);

	// Destructure state
	const { company_name, on_hold, stage, timeline, live_website_url, duda_site_id, preview_site_url, events } = { ...plan };
	const dueDate = plan && calculateDueDate(stage, timeline);

	// Effects
	useEffect(() => {
		const init = async () => {
			await getJwt();
			setMounted(true);
		};
		init();
	}, []);

	// Events
	const nextEvent = events && events.length > 0 && events.find(event => dayjs(event.date).isAfter(dayjs()));

	// Preview Link
	const preview_link = duda_site_id ? `https://dashboard.appfoliowebsites.com/preview/${duda_site_id}` : preview_site_url ? preview_site_url : null;

	return (
		<Component>
			{plan ? (
				<MainWrapper>
					<Helmet>
						<title>{company_name} | AppFolio Investment Management | Project Plan Tool</title>
					</Helmet>
					<Heading>
						<h1 className='mb-4 text-4xl font-bold sm:mt-4'>{company_name}</h1>
						{preview_link && (
							<p className='m-0 text-muted'>
								Preview Site URL:{' '}
								<a href={preview_link} target='_blank' rel='noopener noreferrer'>
									{tidyUrl(preview_link)}
								</a>
							</p>
						)}
						{live_website_url && (
							<p className='m-0 text-muted'>
								Live Website URL:{' '}
								<a href={live_website_url} target='_blank' rel='noopener noreferrer'>
									{tidyUrl(live_website_url)}
								</a>
							</p>
						)}
					</Heading>
					{!on_hold ? (
						<>
							<PanelWrapper>
								<Panel>
									<Name>Project Status:</Name>
									<Value>
										<PlanStatus on_hold={on_hold} stage={stage} dueDate={dueDate} showDelay />
									</Value>
								</Panel>
								<Panel>
									<Name>Current Stage:</Name>
									<Value>{stage}</Value>
								</Panel>
								<Panel>
									<Name>Target Go-Live Date:</Name>
									<Value>{tidyDateWords(timeline.find(stage => stage.name === 'Live').date)}</Value>
								</Panel>
								{nextEvent && (
									<Panel>
										<Name>Upcoming Event:</Name>
										<Event>
											<p>{nextEvent.name}</p>
											<small className='text-muted'>{tidyLocalDateTime(nextEvent.date)}</small>
										</Event>
									</Panel>
								)}
							</PanelWrapper>
							<Timeline plan={plan} />
							<LowerWrapper>
								<Designer designer={designer} />
								<Panel>
									<Arrow />
									<h5 className='text-xl font-semibold'>What we are doing:</h5>
									<WhatWeAreDoing plan={plan} />
									<h5 className='text-xl font-semibold'>What we need:</h5>
									<WhatWeNeed plan={plan} designer={designer} />
									<h5 className='text-xl font-semibold'>Due date:</h5>
									<DueDate plan={plan} />
								</Panel>
							</LowerWrapper>
						</>
					) : (
						<>
							<h4 className='mb-2 text-2xl font-semibold'>
								<i className='fas fa-exclamation-circle text-danger me-1'></i> This project has been put on hold.
							</h4>
							<p>
								Please contact <a href={`mailto:${plan.appfolio_onboarding_contact}`}>{plan.appfolio_onboarding_contact}</a> to continue the development of your website.
							</p>
						</>
					)}
				</MainWrapper>
			) : (
				<LoadingOverlay />
			)}
		</Component>
	);
};

export default View;
