import dayjs from 'dayjs';

export const calculateDueDate = (currentStage, timeline) => {
	const currentIndex = timeline.findIndex(stage => stage.name === currentStage);
	const nextIndex = currentIndex !== 4 ? currentIndex + 1 : currentIndex;
	return timeline[nextIndex].date;
};

export const calculateStatus = (on_hold, stage, dueDate) => {
	// If on hold
	if (on_hold) {
		return 'On Hold';
	}

	// If Live
	else if (stage === 'Live') {
		return 'Live';
	} else {
		// Set today's date
		const currentDate = dayjs().format('MM/DD/YYYY');

		// Define project status
		let status = null;

		// Calculate difference from today
		const diff = dayjs(dueDate).diff(currentDate, 'day');

		// If today or later
		if (Math.sign(diff) === 1 || Math.sign(diff) === 0) {
			status = 'On Time';
		} else {
			const delay = Math.abs(diff);
			status = delay >= 7 ? 'Overdue' : 'Delayed';
		}
		return status;
	}
};

export const calculateWaitingOn = (stage, customer, designer) => {
	let waitingOn = null;
	switch (stage) {
		case 'Welcome':
			waitingOn = designer;
			break;
		case 'Collect':
			waitingOn = customer;
			break;
		case 'Build':
			waitingOn = designer;
			break;
		case 'Review':
			waitingOn = customer;
			break;
		default:
			break;
	}
	return waitingOn;
};
