import styled from 'styled-components';
import { useAccordionButton } from 'react-bootstrap';

export const Subsection = styled.section`
	margin: 1rem 0 2rem;
`;

export const Section = styled.section`
	margin: 3rem 0;
`;

export const Panel = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	padding: 1rem 1.25rem;

	& > :last-child {
		margin-bottom: 0 !important;
	}
`;

export const Name = styled.h6`
	margin: 0 0 0.5rem;
	font-weight: bold;
`;

export const Value = styled.div`
	margin: 0;

	& > :last-child {
		margin-bottom: 0 !important;
	}
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: 100%;
	gap: 1rem;
	margin-top: 1rem;
`;

const Title = styled.h5`
	cursor: pointer;
	margin: 0.5rem 0;
	display: inline-block;
	font-size: 1.25rem;
	font-weight: bold;
`;

export const Toggle = ({ children, eventKey }) => {
	const handleToggleAccordion = useAccordionButton(eventKey);
	return <Title onClick={handleToggleAccordion}>{children}</Title>;
};
