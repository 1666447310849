import { Section, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyParagraphs } from 'utils/Tidy';
import styled from 'styled-components';

const CustomGrid = styled(Grid)`
	grid-template-columns: repeat(2, 1fr);

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const DesignOptions = ({ creative_brief }) => {
	// Destructure props
	const { website_type, website_template, why_did_the_customer_choose_this_design } = { ...creative_brief };
	const exists = website_type || website_template || why_did_the_customer_choose_this_design ? true : false;

	return (
		exists && (
			<Section>
				<h3 className='text-2xl font-semibold'>Design Options</h3>
				<CustomGrid>
					{website_type && (
						<Panel>
							<Name>Website Type</Name>
							<Value>{website_type}</Value>
						</Panel>
					)}
					{website_template && (
						<Panel>
							<Name>Website Template</Name>
							<Value>{website_template}</Value>
						</Panel>
					)}
					{why_did_the_customer_choose_this_design && (
						<Panel>
							<Name>Why did the customer choose this design?</Name>
							<Value>{tidyParagraphs(why_did_the_customer_choose_this_design)}</Value>
						</Panel>
					)}
				</CustomGrid>
			</Section>
		)
	);
};

export default DesignOptions;
