const PageHeader = ({ heading, subheading }) => {
	return (
		<div className='space-y-2 mb-8'>
			<h1 className='text-4xl font-bold'>{heading}</h1>
			<h4 className='text-slate-500'>{subheading}</h4>
		</div>
	);
};

export default PageHeader;
