import { useState, useEffect } from 'react';
import MainWrapper from 'components/shared/MainWrapper';
import PageHeader from 'components/shared/PageHeader';
import { Form, Row, Col, Button, Stack } from 'react-bootstrap';
import FloatingButtons from 'components/shared/FloatingButtons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { tidyDatePicker, tidyLocalDateTime } from 'utils/Tidy';
import { useMessage } from 'utils/MessageContext';
import useSWR, { mutate } from 'swr';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import Confirmation from 'components/shared/Confirmation';
import Assets from './Assets/Assets';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { fetcher } from 'utils/Fetch';
import DropboxPrompt from './DropboxPrompt';

const SquareButton = styled(Button)`
	min-width: auto !important;
	width: 3rem;
	height: 2.5rem;
	padding: 0;
	display: grid;
	place-items: center;

	i {
		margin: 0;
	}
`;

const Event = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--bs-light);
	padding: 0.5rem 0.25rem;

	p {
		margin: 0;
	}

	i {
		cursor: pointer;
	}
`;

const Edit = () => {
	// State
	const [formResponse, setFormResponse] = useState({});
	const [assets, setAssets] = useState([]);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showEventConfirmation, setShowEventConfirmation] = useState(false);
	const [showDropboxPrompt, setShowDropboxPrompt] = useState(false);
	const [eventId, setEventId] = useState('');

	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { setMessage } = useMessage();
	const { data } = useSWR(`/api/v1/plans/${id}`);

	useEffect(() => {
		if (data) {
			setFormResponse(data);
			setAssets(data.assets);
		}
	}, [data]);

	// Destructure dates
	const { timeline, events } = { ...formResponse };

	// Handle input change
	const handleChange = e => {
		const { value, name, type, checked } = e.target;
		if (name === 'assets_url' && value.includes('dropbox.com/sh/')) {
			setShowDropboxPrompt(true);
		}
		setFormResponse(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
	};

	// Handle date change
	const handleDateChange = e => {
		const { value, name } = e.target;
		const timeline = [...formResponse.timeline].map(item => (item.name === name ? { ...item, date: value } : item));
		setFormResponse(prev => ({ ...prev, timeline }));
	};

	// Handle form submit
	const handleSubmit = async e => {
		// Prevent page refresh
		e.preventDefault();

		// Merge state
		const body = { ...formResponse, assets };

		// Submit
		try {
			const data = await fetcher(`/api/v1/plans/${id}`, { method: 'PUT', body: JSON.stringify(body) });
			navigate(`/plan/${data._id}`);
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error saving this project plan.'
			});
		}
	};

	// Handle plan delete
	const handleDelete = async () => {
		// Submit
		try {
			await fetcher(`/api/v1/plans/${id}`, { method: 'DELETE' });
			navigate('/plans');
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error deleting this project plan.'
			});
		}
	};

	// Handle event delete
	const handleEventDelete = async () => {
		const events = formResponse.events.filter(event => event._id !== eventId);

		// Merge state
		const body = { ...formResponse, events };

		// Submit
		try {
			await fetcher(`/api/v1/plans/${id}`, {
				method: 'PUT',
				body: JSON.stringify(body)
			});
			mutate(`/api/v1/plans/${id}`);
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error saving this project plan.'
			});
		}
	};

	// Events
	const upcomingEvents = events && events.length > 0 && events.filter(event => dayjs().isBefore(dayjs(event.date)));
	const pastEvents = events && events.length > 0 && events.filter(event => dayjs().isAfter(dayjs(event.date)));

	return (
		<>
			<Helmet>
				<title>Edit Plan | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={'delete this project plan?'} confirmFunction={handleDelete} />
			<DropboxPrompt show={showDropboxPrompt} setShow={setShowDropboxPrompt} />
			<Confirmation
				show={showEventConfirmation}
				setShow={setShowEventConfirmation}
				message={
					<>
						delete this event? If so, remember to also{' '}
						<a href='https://calendly.com/app/scheduled_events/user/me' target='_blank' rel='noopener noreferrer'>
							delete this event from within your Calendly account.
						</a>
					</>
				}
				confirmFunction={handleEventDelete}
			/>
			<PageHeader heading='Edit Project Plan' subheading='Edit an existing project plan.' />
			{Object.keys(formResponse).length > 0 ? (
				<MainWrapper>
					<Form onSubmit={e => handleSubmit(e)}>
						<h5 className='my-3 text-xl font-semibold'>Project Details</h5>
						<Row>
							<Col lg='6'>
								<div className='mb-3'>
									<Form.Label>Company name</Form.Label>
									<Form.Control name='company_name' value={formResponse.company_name || ''} onChange={e => handleChange(e)} required />
								</div>
								<div className='mb-3'>
									<Form.Label>Company contact</Form.Label>
									<Form.Control type='email' name='company_contact' value={formResponse.company_contact || ''} onChange={e => handleChange(e)} required />
								</div>
								<div className='mb-3'>
									<Form.Label>AppFolio Onboarding Contact</Form.Label>
									<Form.Control type='email' name='appfolio_onboarding_contact' value={formResponse.appfolio_onboarding_contact || ''} onChange={e => handleChange(e)} required />
								</div>
								<div className='mb-3'>
									<Form.Label>Site Type</Form.Label>
									<Form.Select name='site_type' value={formResponse.site_type || ''} onChange={e => handleChange(e)} required>
										<option>Fixed</option>
										<option>Flexible</option>
									</Form.Select>
								</div>
							</Col>
							<Col lg='6'>
								<div className='mb-3'>
									<Form.Label>Duda Site ID</Form.Label>
									<Form.Control type='text' name='duda_site_id' pattern='[A-Za-z0-9]{8}' value={formResponse.duda_site_id || ''} onChange={e => handleChange(e)} />
								</div>
								<div className='mb-3'>
									<Form.Label>Live Website URL</Form.Label>
									<Form.Control type='url' name='live_website_url' value={formResponse.live_website_url || ''} onChange={e => handleChange(e)} />
								</div>
								<div className='mb-3'>
									<Form.Label>Client Access URL</Form.Label>
									<Form.Control type='url' name='client_access_url' value={formResponse.client_access_url || ''} onChange={e => handleChange(e)} />
								</div>
								<div className='mt-5'>
									<Form.Check type='switch' id='hold-switch' label='Put this project on hold' name='on_hold' checked={formResponse.on_hold} onChange={e => handleChange(e)} />
								</div>
							</Col>
						</Row>
						<h5 className='my-3 text-xl font-semibold'>SalesForce Details</h5>
						<Row>
							<Col lg='6'>
								<div className='mb-3'>
									<Form.Label>V+ Object Name</Form.Label>
									<Form.Control name='sf_vplus_object_id' value={formResponse.sf_vplus_object_id || ''} onChange={e => handleChange(e)} required />
								</div>
							</Col>
							<Col lg='6'>
								<div className='mb-3'>
									<Form.Label>V+ Object URL</Form.Label>
									<Form.Control name='sf_vplus_object_url' value={formResponse.sf_vplus_object_url || ''} onChange={e => handleChange(e)} required />
								</div>
							</Col>
						</Row>
						<Row>
							<Col lg='6'>
								<h5 className='my-3 text-xl font-semibold'>Timeline</h5>
								<div className='mb-3'>
									<Form.Label>Current Stage</Form.Label>
									<Form.Select name='stage' value={formResponse.stage || ''} onChange={e => handleChange(e)} required>
										<option disabled={formResponse.stage !== 'Welcome' && formResponse.stage !== 'Collect' && true}>Welcome</option>
										<option disabled={formResponse.stage !== 'Welcome' && formResponse.stage !== 'Collect' && formResponse.stage !== 'Build' && true}>Collect</option>
										<option disabled={formResponse.stage !== 'Collect' && formResponse.stage !== 'Build' && formResponse.stage !== 'Review' && true}>Build</option>
										<option disabled={formResponse.stage !== 'Build' && formResponse.stage !== 'Review' && formResponse.stage !== 'Live' && true}>Review</option>
										<option disabled={formResponse.stage !== 'Review' && formResponse.stage !== 'Live' && true}>Live</option>
									</Form.Select>
								</div>
								{timeline.map(stage => (
									<div key={stage.name} className='mb-3'>
										<Form.Label>{stage.name} Date</Form.Label>
										<Form.Control type='date' name={stage.name} value={tidyDatePicker(stage.date) || ''} onChange={e => handleDateChange(e)} />
									</div>
								))}
							</Col>
							<Col lg='6'>
								<h5 className='my-3 text-xl font-semibold'>Assets</h5>
								<div className='mb-3'>
									<Form.Label>Dropbox URL</Form.Label>
									<Stack direction='horizontal' gap={2}>
										<Form.Control className='border-0 shadow-sm w-100' type='url' name='assets_url' placeholder='eg. dropbox.com/request/request_id' pattern='https://www.dropbox.com/.+' value={formResponse.assets_url || ''} onChange={e => handleChange(e)} />
										{!formResponse.assets_url && (
											<SquareButton href='https://www.dropbox.com/requests' target='_blank' rel='noopener noreferrer' title='Create a new file request'>
												<i className='fas fa-edit'></i>
											</SquareButton>
										)}
									</Stack>
								</div>
								<div className='mb-3'>
									<Form.Label>Assets Checklist</Form.Label>
									<Assets assets={assets} setAssets={setAssets} />
								</div>
							</Col>
						</Row>
						<Row>
							<Col lg='6'>
								<h5 className='my-3 text-xl font-semibold'>Scheduling</h5>
								<div className='mb-3'>
									<Form.Label>Kick-Off Call URL</Form.Label>
									<Form.Control type='url' name='kick_off_url' placeholder='eg. calendly.com/user_name/event_name' pattern='https://calendly.com/.+' value={formResponse.kick_off_url || ''} onChange={e => handleChange(e)} />
								</div>
								<div className='mb-3'>
									<Form.Label>Revisions Call URL</Form.Label>
									<Form.Control type='url' name='revisions_url' placeholder='eg. calendly.com/user_name/event_name' pattern='https://calendly.com/.+' value={formResponse.revisions_url || ''} onChange={e => handleChange(e)} />
								</div>
							</Col>
							<Col lg='6'>
								<h5 className='my-3 text-xl font-semibold'>Events</h5>
								<h6 className='font-semibold text-sky-800'>Upcoming</h6>
								{upcomingEvents.length > 0 ? (
									upcomingEvents.map(event => (
										<Event key={event._id}>
											<div>
												<p>{event.name}</p>
												<p className='text-muted'>{tidyLocalDateTime(event.date)}</p>
											</div>
											<i
												className='button-icon fas fa-trash-alt text-danger'
												onClick={() => {
													setShowEventConfirmation(true);
													setEventId(event._id);
												}}></i>
										</Event>
									))
								) : (
									<p>No upcoming events.</p>
								)}
								<h6 className='mt-3 font-semibold text-sky-800'>Past</h6>
								{pastEvents.length > 0 ? (
									pastEvents.map(event => (
										<Event key={event._id}>
											<div>
												<p>{event.name}</p>
												<p className='text-muted'>{tidyLocalDateTime(event.date)}</p>
											</div>
											<i
												className='button-icon fas fa-trash-alt text-danger'
												onClick={() => {
													setShowEventConfirmation(true);
													setEventId(event._id);
												}}></i>
										</Event>
									))
								) : (
									<p>No past events.</p>
								)}
							</Col>
						</Row>
						<FloatingButtons className='mt-4'>
							<Button type='submit'>
								Save Plan <i className='fas fa-save'></i>
							</Button>
							<Button variant='danger' onClick={() => setShowConfirmation(true)}>
								Delete Plan <i className='fas fa-trash-alt'></i>
							</Button>
							<Button as={Link} to='/plans' variant='secondary'>
								Cancel <i className='fas fa-times-circle'></i>
							</Button>
						</FloatingButtons>
					</Form>
				</MainWrapper>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Edit;
