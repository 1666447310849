import { Field } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';

const GeneralContent = () => {
	return (
		<>
			<div className='mb-3'>
				<Form.Label>Project Notes</Form.Label>
				<Field as='textarea' className='form-control' rows={5} name='project_notes' />
			</div>
			<div className='mb-3'>
				<Form.Label className='mb-0'>Website Goals</Form.Label>
				<Form.Text className='d-block mb-2 text-muted'>What does the customer want to achieve with their new website?</Form.Text>
				<Field as='textarea' className='form-control' rows={5} name='website_goals' />
			</div>
			<Row>
				<Col lg={6}>
					<div className='mb-3'>
						<Form.Label className='mb-0'>Who are we marketing the website to?</Form.Label>
						<Form.Text className='d-block mb-2 text-muted'>Check all that apply</Form.Text>
						<Form.Check type='checkbox' id='high_net_worth_individuals' custom>
							<Field as={Form.Check.Input} type='checkbox' name='who_are_we_marketing_the_website_to' value='High Net Worth Individuals' />
							<Form.Check.Label>High Net Worth Individuals</Form.Check.Label>
						</Form.Check>
						<Form.Check type='checkbox' id='institutional_pension' custom>
							<Field as={Form.Check.Input} type='checkbox' name='who_are_we_marketing_the_website_to' value='Institutional/Pension' />
							<Form.Check.Label>Institutional/Pension</Form.Check.Label>
						</Form.Check>
						<Form.Check type='checkbox' id='family_office' custom>
							<Field as={Form.Check.Input} type='checkbox' name='who_are_we_marketing_the_website_to' value='Family Office' />
							<Form.Check.Label>Family Office</Form.Check.Label>
						</Form.Check>
						<Form.Check type='checkbox' id='other' custom>
							<Field as={Form.Check.Input} type='checkbox' name='who_are_we_marketing_the_website_to' value='Other' />
							<Form.Check.Label>Other</Form.Check.Label>
						</Form.Check>
					</div>
				</Col>
				<Col lg={6}>
					<div className='mb-3'>
						<Form.Label className='mb-0'>What kind of deal structures do you manage?</Form.Label>
						<Form.Text className='d-block mb-2 text-muted'>Check all that apply</Form.Text>
						<Form.Check type='checkbox' id='syndication' custom>
							<Field as={Form.Check.Input} type='checkbox' name='what_kind_of_deal_structures_do_you_manage' value='Syndication' />
							<Form.Check.Label>Syndication</Form.Check.Label>
						</Form.Check>
						<Form.Check type='checkbox' id='fund' custom>
							<Field as={Form.Check.Input} type='checkbox' name='what_kind_of_deal_structures_do_you_manage' value='Fund' />
							<Form.Check.Label>Fund</Form.Check.Label>
						</Form.Check>
						<Form.Check type='checkbox' id='joint_venture' custom>
							<Field as={Form.Check.Input} type='checkbox' name='what_kind_of_deal_structures_do_you_manage' value='Joint Venture' />
							<Form.Check.Label>Joint Venture</Form.Check.Label>
						</Form.Check>
						<Form.Check type='checkbox' id='deal_by_deal' custom>
							<Field as={Form.Check.Input} type='checkbox' name='what_kind_of_deal_structures_do_you_manage' value='Deal by Deal' />
							<Form.Check.Label>Deal by Deal</Form.Check.Label>
						</Form.Check>
						<Form.Check type='checkbox' id='other_2' custom>
							<Field as={Form.Check.Input} type='checkbox' name='what_kind_of_deal_structures_do_you_manage' value='Other' />
							<Form.Check.Label>Other</Form.Check.Label>
						</Form.Check>
					</div>
				</Col>
			</Row>
			<div className='mb-3'>
				<Form.Label>How do you normally raise funds/attract investors?</Form.Label>
				<Field as='textarea' className='form-control' name='how_do_you_normally_raise_funds_attract_investors' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label>How do you showcase your past projects? What is important to showcase?</Form.Label>
				<Field as='textarea' className='form-control' name='how_do_you_showcase_your_past_projects_what_is_important_to_showcase' rows={5} />
			</div>
		</>
	);
};

export default GeneralContent;
