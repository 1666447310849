import PageHeader from 'components/shared/PageHeader';
import MainWrapper from 'components/shared/MainWrapper';
import BriefsTable from './BriefsTable';
import useSWR from 'swr';
import { useLogin } from 'utils/UserContext';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';

const NoBriefs = styled.div`
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
	padding: 2rem 1rem;
	background: white;
	text-align: center;
	margin: 2rem auto;
`;

const Briefs = () => {
	// Hooks
	const { user } = useLogin();
	const { email } = { ...user };
	const { data: briefs } = useSWR(`/api/v1/briefs/user/${email}`);
	const navigate = useNavigate();

	// Go to brief
	const goToBrief = e => {
		const selected = e[0];
		const selectedSite = briefs.find(brief => brief._id === selected._id);
		navigate(`/brief/${selectedSite._id}`);
	};

	return (
		<>
			<Helmet>
				<title>My Project briefs | AppFolio Investment Management | Project brief Tool</title>
			</Helmet>
			<PageHeader heading='My Project briefs' subheading='View all of my project briefs.' />
			<MainWrapper>
				{briefs ? (
					briefs.length > 0 ? (
						<>
							<Typeahead className='border-0 shadow-sm' id='basic-typeahead-single' labelKey='name' onChange={e => goToBrief(e)} options={briefs.map(brief => ({ _id: brief._id, name: brief.company_name }))} placeholder='Search for a company...' minLength={2} />
							<BriefsTable briefs={briefs} />
						</>
					) : (
						<NoBriefs>
							<div>No briefs to display.</div>
						</NoBriefs>
					)
				) : (
					<LoadingOverlay />
				)}
			</MainWrapper>
		</>
	);
};

export default Briefs;
