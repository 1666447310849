import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Assets = ({ assets, id }) => {
	return (
		<>
			<div className='my-3'>
				{assets?.map(asset => (
					<div key={asset._id} className='mb-1'>
						{asset.complete ? <i className='far fa-check-circle text-success me-2'></i> : <i className='far fa-times-circle text-danger me-2'></i>}
						{asset.name}
					</div>
				))}
			</div>
			<Button as={Link} to={`/edit/${id}`}>
				Edit assets checklist <i className='fas fa-clipboard-list'></i>
			</Button>
		</>
	);
};

export default Assets;
