import { Field } from 'formik';
import { Form } from 'react-bootstrap';

const GranularQuestions = () => {
	return (
		<>
			<div className='mb-3'>
				<Form.Label>What types of properties are you typically looking to deal with (residential, commercial, alternative-land, industrial)?</Form.Label>
				<Field as='textarea' className='form-control' name='what_type_of_properties_are_you_typically_looking_to_deal_with' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label>What investment strategies do you employ (core, core plus, value-added, opportunistic)? Are there any elements of your strategy that you want to highlight on the website?</Form.Label>
				<Field as='textarea' className='form-control' name='what_investment_strategies_do_you_employ' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label className='font-bold'>Would you like to include a section about your team members? Please provide bios and headshots.</Form.Label>
				<Field as={Form.Check} className='my-1' type='radio' name='would_you_like_to_include_a_section_about_your_team_members' id='yes' value='Yes' label='Yes' />
				<Field as={Form.Check} className='my-1' type='radio' name='would_you_like_to_include_a_section_about_your_team_members' id='no' value='No' label='No' />
			</div>
			<div className='mb-3'>
				<Form.Label className='m-0'>Would you like to highlight any performance statistics on your website?</Form.Label>
				<Form.Text className='d-block mb-2 text-muted'>i.e. Rate of return, equity under management (EUM), assets under management (AUM), number of investors, number of assets in portfolio, years of experience, etc.</Form.Text>
				<Field as='textarea' className='form-control' name='would_you_like_to_highlight_any_performance_statistics_on_your_website' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label className='m-0'>Do you want to highlight your portfolio/past projects? What information would you like to be included and how many would you like to showcase?</Form.Label>
				<Form.Text className='d-block mb-2 text-muted'>i.e. Location, purchase timing, number of units per square foot, purchase price, other asset KPI’s, etc.</Form.Text>
				<Field as='textarea' className='form-control' name='do_you_want_to_highlight_your_portfolio_past_projects' rows={5} />
			</div>
		</>
	);
};

export default GranularQuestions;
