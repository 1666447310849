import styled from 'styled-components';

import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Component = styled.div`
	display: grid;
	place-items: center;
`;

const Panel = styled.div`
	width: 720px;
	max-width: 100%;
	background: white;
	padding: 2rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
	text-align: center;
`;

const Icon = styled.i`
	font-size: 5rem;
	color var(--bs-success);
	margin-bottom: 1.5rem;
`;

const Success = ({ importedCsv, collectionData }) => {
	const { name, site_id } = collectionData;
	const fieldCount = importedCsv.length;

	return (
		<Component>
			<Panel>
				<Icon className='fas fa-check-circle' />
				<h3 className='text-2xl font-semibold'>Success!</h3>
				<div>
					The <strong>{name}</strong> collection was created with <strong>{fieldCount}</strong> row{fieldCount > 1 ? 's' : ''} of data in site <strong>{site_id}</strong>.
				</div>
				<Button className='mt-4' as={Link} to={'/'}>
					Go Home <i className='fas fa-home'></i>
				</Button>
			</Panel>
		</Component>
	);
};

export default Success;
