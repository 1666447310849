import styled from 'styled-components';

const Wrapper = styled.div`
	max-width: 1280px;
	margin: 0 auto;
`;

const MainWrapper = ({ children }) => {
	return <Wrapper>{children}</Wrapper>;
};

export default MainWrapper;
