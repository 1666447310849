import { useEffect, useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import MainWrapper from 'components/shared/MainWrapper';
import { Button, Form } from 'react-bootstrap';
import PlansTable from './PlansTable';
import useSWR from 'swr';
import { useLogin } from 'utils/UserContext';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import { useMessage } from 'utils/MessageContext';
import Schedule from './Schedule';

const Component = styled.div``;

const NoPlans = styled.div`
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
	padding: 2rem 1rem;
	background: white;
	text-align: center;
	margin: 2rem auto;
`;

const CustomLink = styled(Link)`
	color: white !important;
	font-weight: bold;
	text-decoration: underline;
`;

const Heading = styled.h5`
	margin: 2rem 0 0.75rem;
	font-size: 1.25rem;
	font-weight: bold;
`;

const Plans = () => {
	// Hooks
	const { user } = useLogin();
	const { _id } = { ...user };
	const { data: userData } = useSWR(() => `/api/v1/users/${_id}`);
	const { email, access_token } = { ...userData };
	const { data: planData } = useSWR(() => `/api/v1/plans/user/${email}`);
	const navigate = useNavigate();
	const { setMessage } = useMessage();

	// State
	const [hideLivePlans, setHideLivePlans] = useState(true);
	const [plans, setPlans] = useState([]);

	// Effects
	useEffect(() => {
		if (userData && !access_token) {
			setMessage({
				variant: 'danger',
				text: (
					<>
						Before proceeding, please add a Calendly access token to your account <CustomLink to={'/edit/designer'}>here.</CustomLink>
					</>
				),
				global: true,
				persist: true
			});
		}
	}, [userData, access_token, setMessage]);

	useEffect(() => {
		setPlans(planData);
	}, [planData]);

	useEffect(() => {
		if (planData) {
			hideLivePlans ? setPlans(planData.filter(plan => plan.stage !== 'Live')) : setPlans(planData);
		}
	}, [planData, hideLivePlans]);

	// Go to plan
	const goToPlan = e => {
		const selected = e[0];
		const selectedSite = plans.find(plan => plan._id === selected._id);
		navigate(`/plan/${selectedSite._id}`);
	};

	return (
		<>
			<Helmet>
				<title>My Project Plans | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading='My Project Plans' subheading='View all of my project plans.' />
			<MainWrapper>
				{plans ? (
					plans.length > 0 ? (
						<Component>
							<Heading>My Schedule</Heading>
							<Schedule plans={plans} />
							<Heading>My Plans</Heading>
							<PlansTable plans={plans} />
							<Heading>Search Options</Heading>
							<Typeahead className='border-0 shadow-sm mb-3' id='basic-typeahead-single' labelKey='name' onChange={e => goToPlan(e)} options={plans.map(plan => ({ _id: plan._id, name: plan.company_name }))} placeholder='Search for a company...' minLength={2} />
							<Form.Switch id='hide-live-plans' label='Hide Live Project Plans' checked={hideLivePlans} onChange={() => setHideLivePlans(!hideLivePlans)} />
						</Component>
					) : (
						<NoPlans>
							<div>No plans to display.</div>
							<Button className='mt-2' as={Link} to={'/plan/new'}>
								Create a New Project Plan <i className='fas fa-folder-plus'></i>
							</Button>
						</NoPlans>
					)
				) : (
					<LoadingOverlay />
				)}
			</MainWrapper>
		</>
	);
};

export default Plans;
