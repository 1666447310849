import logo from 'img/app/appfolio_logo.svg';
import styled from 'styled-components';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { tidyLocalDateTime } from 'utils/Tidy';
import LoadingOverlay from 'components/shared/LoadingOverlay';

// Sections
import ProjectDetails from './Sections/ProjectDetails';
import Timeline from './Sections/Timeline';
import DomainInfo from './Sections/DomainInfo';
import CompanyInfo from './Sections/CompanyInfo';
import WebsiteContent from './Sections/WebsiteContent';
import DesignOptions from './Sections/DesignOptions';
import DesignAesthetics from './Sections/DesignAesthetics';

const Component = styled.div`
	padding: 3rem;
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

const Header = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 3rem;
`;

const View = () => {
	// Hooks
	const { id } = useParams();
	const { data } = useSWR(`/api/v1/briefs/${id}`);

	// Destructure data
	const { creative_brief, timeline } = { ...data };
	const { company_name, last_saved } = { ...creative_brief };

	return data ? (
		<Component>
			<Header>
				<img src={logo} alt='AppFolio Investment Management Logo' width='75' />
				<div>
					<h1 className='text-4xl font-bold mb-2'>Creative Brief - {company_name}</h1>
					<h4 className='text-slate-500'>Last updated {tidyLocalDateTime(last_saved)}</h4>
				</div>
			</Header>
			<ProjectDetails creative_brief={creative_brief} />
			<Timeline timeline={timeline} />
			<DomainInfo creative_brief={creative_brief} />
			<CompanyInfo creative_brief={creative_brief} />
			<WebsiteContent creative_brief={creative_brief} />
			<DesignOptions creative_brief={creative_brief} />
			<DesignAesthetics creative_brief={creative_brief} />
		</Component>
	) : (
		<LoadingOverlay />
	);
};

export default View;
