import styled from 'styled-components';

import { tidyDateWords } from 'utils/Tidy';
import { calculateDueDate, calculateStatus } from 'utils/Stages';
import PlanStatus from 'components/shared/PlanStatus';
import { useNavigate } from 'react-router-dom';
import useTableSort from 'utils/Sort';

const Table = styled.div`
	border-radius: 0.5rem;
	overflow: auto;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
`;

const TableCell = styled.div`
	padding: 0.5rem 0.75rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(200px, 2fr)) repeat(4, minmax(150px, 1fr));
`;

const HeaderRow = styled(Row)`
	${TableCell} {
		background: var(--darkest);
		color: white;
		cursor: pointer;
	}
`;

const TableBody = styled.div``;

const ExtLink = styled.a`
	text-decoration: underline dotted;
`;

const TableRow = styled(Row)`
	transition: 0.1s;
	background: white;
	cursor: pointer;

	&:hover,
	&:focus {
		background: var(--Gold);
		color: white;

		${ExtLink}, i.fas {
			color: white !important;
		}
	}
`;

const NoPlans = styled(TableCell)`
	text-align: center;
	background: white;
`;

const PlansTable = ({ plans }) => {
	// Hooks
	const navigate = useNavigate();

	// Handle row click
	const handleClick = (e, id) => {
		if (!e.target.href) {
			navigate(`/plan/${id}`);
		}
	};

	// Render functions
	const renderIcon = sortType => {
		const { key = 'createdAt', direction = 'descending' } = { ...sortConfig };
		if (key === sortType) {
			if (direction === 'ascending') {
				return <i className='fas fa-angle-down text-muted ms-1'></i>;
			}
			if (direction === 'descending') {
				return <i className='fas fa-angle-up text-muted ms-1'></i>;
			}
		}
	};

	// Use sort hook
	const flattenedPlans = plans.map(plan => ({ ...plan, status: calculateStatus(plan.on_hold, plan.stage, calculateDueDate(plan.stage, plan.timeline)), target_go_live: plan.timeline.find(stage => stage.name === 'Live').date }));
	const { items: sortedPlans, requestSort, sortConfig } = useTableSort(flattenedPlans, { key: 'createdAt' });

	return (
		<Table>
			<HeaderRow>
				<TableCell onClick={() => requestSort('company_name')}>Company Name {renderIcon('company_name')}</TableCell>
				<TableCell onClick={() => requestSort('status')}>Status {renderIcon('status')}</TableCell>
				<TableCell onClick={() => requestSort('stage')}>Stage {renderIcon('stage')}</TableCell>
				<TableCell onClick={() => requestSort('target_go_live')}>Target Go live {renderIcon('target_go_live')}</TableCell>
				<TableCell onClick={() => requestSort('sf_vplus_object_id')}>V+ Object Link {renderIcon('sf_vplus_object_id')}</TableCell>
				<TableCell onClick={() => requestSort('duda_site_id')}>Edit Site Link {renderIcon('duda_site_id')}</TableCell>
			</HeaderRow>
			{sortedPlans.length > 0 ? (
				<TableBody>
					{sortedPlans.map(plan => (
						<TableRow key={plan._id} onClick={e => handleClick(e, plan._id)}>
							<TableCell>{plan.company_name}</TableCell>
							<TableCell>
								<PlanStatus on_hold={plan.on_hold} stage={plan.stage} dueDate={calculateDueDate(plan.stage, plan.timeline)} showDelay />
							</TableCell>
							<TableCell>{plan.stage}</TableCell>
							<TableCell>{tidyDateWords(plan.timeline.find(stage => stage.name === 'Live').date)}</TableCell>
							<TableCell>
								<ExtLink href={plan.sf_vplus_object_url} target='_blank' rel='noopener noreferrer'>
									{plan.sf_vplus_object_id}
								</ExtLink>
							</TableCell>
							<TableCell>
								<ExtLink href={`https://dashboard.appfolioimwebsites.com/home/site/${plan.duda_site_id}`} target='_blank' rel='noopener noreferrer'>
									{plan.duda_site_id}
								</ExtLink>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			) : (
				<NoPlans>No plans to display.</NoPlans>
			)}
		</Table>
	);
};

export default PlansTable;
