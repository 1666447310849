import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import { useParams, Link } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Formik, Form } from 'formik';
import { Accordion, Button, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { useMessage } from 'utils/MessageContext';
import FloatingButtons from 'components/shared/FloatingButtons';
import { Toggle } from 'components/Briefs/Shared/Components';
import { fetcher } from 'utils/Fetch';

// Sections
import ProjectDetails from 'components/Briefs/Sections/ProjectDetails';
import Timeline from 'components/Briefs/Sections/Timeline';
import DomainInfo from 'components/Briefs/Sections/DomainInfo';
import CompanyInfo from 'components/Briefs/Sections/CompanyInfo';
import WebsiteContent from 'components/Briefs/Sections/WebsiteContent';
import DesignOptions from 'components/Briefs/Sections/DesignOptions';
import DesignAesthetics from 'components/Briefs/Sections/DesignAesthetics';
import Assets from 'components/Briefs/Sections/Assets';
import { useEffect, useState } from 'react';
import { ChevronLeft } from 'lucide-react';

const Section = styled.section`
	border-bottom: 2px solid var(--bs-light);
	padding: 1rem 0;
`;

const LinkHolder = styled.div`
	display: flex;
	align-items: end;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;

	@media (max-width: 991px) {
		flex-direction: row;
		justify-content: start;
		gap: 2rem;
	}
`;

const Brief = () => {
	// Hooks
	const { id } = useParams();
	const { data: plan, isValidating, mutate } = useSWR(`/api/v1/briefs/${id}`);
	const { message, setMessage } = useMessage();

	// State
	const [isDirty, setIsDirty] = useState(false);

	// Destructure data
	const { company_name, appfolio_onboarding_contact, live_website_url, company_contact, timeline, site_type, assets, creative_brief } = { ...plan };
	const {
		project_type,
		billing_date_agreement,
		domain_name,
		domain_registrar,
		domain_ownership,
		is_email_set_up_on_this_domain,
		registrar_username,
		registrar_password,
		contact_details,
		how_do_you_showcase_your_past_projects_what_is_important_to_showcase,
		project_notes,
		website_goals,
		who_are_we_marketing_the_website_to,
		what_kind_of_deal_structures_do_you_manage,
		how_do_you_normally_raise_funds_attract_investors,
		how_long_have_you_been_in_business,
		what_are_your_values_or_mission_statement,
		how_do_you_currently_attract_new_investors,
		what_do_you_like_or_dislike_about_your_current_website,
		give_me_an_example_of_a_website_you_really_like,
		what_type_of_properties_are_you_typically_looking_to_deal_with,
		what_investment_strategies_do_you_employ,
		would_you_like_to_include_a_section_about_your_team_members,
		would_you_like_to_highlight_any_performance_statistics_on_your_website,
		do_you_want_to_highlight_your_portfolio_past_projects,
		website_template,
		why_did_the_customer_choose_this_design,
		logo_status,
		logo_notes,
		color_scheme,
		photos
	} = { ...creative_brief };

	// Handlers
	const handleSave = async values => {
		try {
			await fetcher(`/api/v1/briefs/${id}`, {
				method: 'PUT',
				body: JSON.stringify({ creative_brief: { ...values, last_saved: new Date() } })
			});
			await mutate();
			setIsDirty(false);
			setMessage({ variant: 'success', text: `Creative brief was saved successfully.` });
		} catch (error) {
			setMessage({ variant: 'danger', text: `There was an error saving this creative brief.` });
		}
	};

	// Formik
	const initialValues = {
		appfolio_onboarding_contact: appfolio_onboarding_contact || '',
		company_name: company_name || '',
		existing_website_url: live_website_url || '',
		final_approver: company_contact || '',
		website_type: site_type || '',
		project_type: project_type || '',
		billing_date_agreement: billing_date_agreement || [],
		domain_name: domain_name || '',
		domain_registrar: domain_registrar || '',
		domain_ownership: domain_ownership || '',
		is_email_set_up_on_this_domain: is_email_set_up_on_this_domain || '',
		registrar_username: registrar_username || '',
		registrar_password: registrar_password || '',
		contact_details: contact_details || 'Company Name: \nPhone: \nFax: \nEmail: \nAddress: \nOffice Hours: \nSocial Media Accounts:',
		how_do_you_showcase_your_past_projects_what_is_important_to_showcase: how_do_you_showcase_your_past_projects_what_is_important_to_showcase || '',
		project_notes: project_notes || '',
		website_goals: website_goals || '',
		who_are_we_marketing_the_website_to: who_are_we_marketing_the_website_to || [],
		what_kind_of_deal_structures_do_you_manage: what_kind_of_deal_structures_do_you_manage || [],
		how_do_you_normally_raise_funds_attract_investors: how_do_you_normally_raise_funds_attract_investors || '',
		how_long_have_you_been_in_business: how_long_have_you_been_in_business || '',
		what_are_your_values_or_mission_statement: what_are_your_values_or_mission_statement || '',
		how_do_you_currently_attract_new_investors: how_do_you_currently_attract_new_investors || '',
		what_do_you_like_or_dislike_about_your_current_website: what_do_you_like_or_dislike_about_your_current_website || '',
		give_me_an_example_of_a_website_you_really_like: give_me_an_example_of_a_website_you_really_like || '',
		what_type_of_properties_are_you_typically_looking_to_deal_with: what_type_of_properties_are_you_typically_looking_to_deal_with || '',
		what_investment_strategies_do_you_employ: what_investment_strategies_do_you_employ || '',
		would_you_like_to_include_a_section_about_your_team_members: would_you_like_to_include_a_section_about_your_team_members || '',
		would_you_like_to_highlight_any_performance_statistics_on_your_website: would_you_like_to_highlight_any_performance_statistics_on_your_website || '',
		do_you_want_to_highlight_your_portfolio_past_projects: do_you_want_to_highlight_your_portfolio_past_projects || '',
		website_template: website_template || '',
		why_did_the_customer_choose_this_design: why_did_the_customer_choose_this_design || '',
		logo_status: logo_status || '',
		logo_notes: logo_notes || '',
		color_scheme: color_scheme || '',
		photos: photos || ''
	};

	// Sections
	const sections = [
		{
			name: 'Project Details',
			component: ProjectDetails,
			complete: appfolio_onboarding_contact && company_name && live_website_url && company_contact && project_type && billing_date_agreement.length > 0 ? true : false
		},
		{
			name: 'Company Info',
			component: CompanyInfo,
			complete: contact_details && contact_details !== 'Company Name: \nPhone: \nFax: \nEmail: \nAddress: \nOffice Hours: \nSocial Media Accounts:' ? true : false
		},
		{
			name: 'Domain Info',
			component: DomainInfo,
			complete: domain_name && domain_registrar && domain_ownership && is_email_set_up_on_this_domain && registrar_username && registrar_password ? true : false
		},
		{
			name: 'Website Content',
			component: WebsiteContent,
			complete: project_notes && website_goals && who_are_we_marketing_the_website_to.length > 0 && what_kind_of_deal_structures_do_you_manage.length > 0 && how_do_you_normally_raise_funds_attract_investors && how_do_you_showcase_your_past_projects_what_is_important_to_showcase && how_long_have_you_been_in_business && what_kind_of_deal_structures_do_you_manage && how_do_you_currently_attract_new_investors && what_do_you_like_or_dislike_about_your_current_website && give_me_an_example_of_a_website_you_really_like && what_type_of_properties_are_you_typically_looking_to_deal_with && what_investment_strategies_do_you_employ && would_you_like_to_highlight_any_performance_statistics_on_your_website && do_you_want_to_highlight_your_portfolio_past_projects ? true : false
		},

		{
			name: 'Design Options',
			component: DesignOptions,
			complete: site_type && website_template && why_did_the_customer_choose_this_design ? true : false
		},
		{
			name: 'Design Aesthetics',
			component: DesignAesthetics,
			complete: logo_status && logo_notes && color_scheme && photos ? true : false
		},
		{
			name: 'Assets',
			component: Assets,
			complete: assets?.length > 0 ? true : false
		},
		{
			name: 'Timeline',
			component: Timeline,
			complete: true
		}
	];

	// Effects
	useEffect(() => {
		if (isDirty && !message) {
			setMessage({
				variant: 'warning',
				text: 'You have unsaved changes in this creative brief.'
			});
		}
	}, [isDirty, message, setMessage]);

	return (
		<>
			<Row className='justify-content-between'>
				<Col lg={8}>
					<PageHeader heading='Creative Brief' subheading='Generate a creative brief with all of the details required to complete the website project.' />
				</Col>
				{creative_brief && (
					<Col lg={4}>
						<LinkHolder>
							<a className='text-primary' href={`/brief/${id}/view`} target='_blank' rel='noopener noreferrer'>
								View Only Link <i className='fas fa-external-link-alt ms-2'></i>
							</a>
							<a className='text-primary' href={`/brief/${id}/content`} target='_blank' rel='noopener noreferrer'>
								Writing Content Link<i className='fas fa-external-link-alt ms-2'></i>
							</a>
						</LinkHolder>
					</Col>
				)}
			</Row>
			{plan ? (
				<>
					<Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSave}>
						{({ values, dirty }) => {
							if (dirty && !isDirty) setIsDirty(true);
							return (
								<Form>
									<Accordion defaultActiveKey={1}>
										{sections.map((section, i) => (
											<Section key={i + 1}>
												<Toggle eventKey={i + 1}>
													<small>{section.complete ? <i className='far fa-check-circle text-success me-1'></i> : <i className='far fa-times-circle text-danger me-1'></i>}</small> {section.name} <i className='fas fa-angle-down text-muted ms-1'></i>
												</Toggle>
												<Accordion.Collapse eventKey={i + 1}>
													<section.component timeline={timeline} values={values} assets={assets} id={id} />
												</Accordion.Collapse>
											</Section>
										))}
									</Accordion>
									<FloatingButtons>
										<Button as={Link} to={`/plan/${id}`} variant='dark' className='flex items-center justify-center gap-1'>
											<ChevronLeft size={16} />
											<div>Back to Plan</div>
										</Button>
										<Button type='submit' disabled={isValidating}>
											{!isValidating ? (
												<>
													Save <i className='fas fa-save'></i>
												</>
											) : (
												<Spinner size='sm' />
											)}
										</Button>
										<Button as={Link} variant='secondary' to={`/plan/${id}`}>
											Cancel <i className='fas fa-times-circle'></i>
										</Button>
									</FloatingButtons>
								</Form>
							);
						}}
					</Formik>
				</>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Brief;
