import { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { tidyDateMonthOnly } from 'utils/Tidy';
import { Link } from 'react-router-dom';
import { calculateStatus, calculateDueDate } from 'utils/Stages';
import { Form } from 'react-bootstrap';

const Component = styled.div`
	display: grid;
	grid-template-columns: minmax(10rem, max-content) 1fr;
	gap: 2rem;

	@media (max-width: 767px) {
		grid-template-columns: auto;
		gap: 1rem;
	}
`;

const FormWrapper = styled.div``;

const EventWrapper = styled.div`
	background: white;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
	display: grid;
`;

const NoEvents = styled.div`
	place-self: center;
	padding: 1rem;
`;

const DateRow = styled.div`
	display: grid;
	grid-template-columns: minmax(10rem, max-content) 1fr;
	padding: 1rem;
	border-bottom: 2px solid var(--bs-light);

	&:last-of-type {
		border: 0;
	}
`;

const Date = styled.h5`
	margin: 0;
	font-size: 1.25rem;
	font-weight: bold;
`;

const Events = styled.div`
	display: grid;
	gap: 1rem;
`;

const Event = styled.div`
	display: grid;
	grid-template-columns: 1rem max-content;
	gap: 1rem;
`;

const Name = styled.div`
	color: black;
	font-weight: bold;
`;

const Company = styled.div`
	color: var(--bs-secondary);
`;

const Details = styled(Link)`
	text-decoration: none !important;
	color: inherit !important;

	&:hover,
	&:focus {
		${Name} {
			text-decoration: underline;
		}
	}
`;

const Status = ({ status }) => {
	switch (status) {
		case 'On Time':
			return <i className='fas fa-check-circle text-success mt-1'></i>;

		case 'Delayed':
			return <i className='fas fa-exclamation-circle text-warning mt-1'></i>;

		case 'Overdue':
			return <i className='fas fa-times-circle text-danger mt-1'></i>;

		case 'Live':
			return <i className='fas fa-check-circle text-primary mt-1'></i>;

		default:
			return <i className='fas fa-question-circle text-secondary mt-1'></i>;
	}
};

const Schedule = ({ plans }) => {
	// State
	const [periodDays, setPeriodDays] = useState(7);

	// Reduce to upcoming events
	const upcomingDates = plans.reduce((total, current) => {
		current.timeline.forEach(event => {
			if (dayjs(event.date).isAfter(dayjs()) && dayjs(event.date).isBefore(dayjs().add(periodDays, 'day'))) {
				const parsedDate = tidyDateMonthOnly(event.date);
				const existing = total.find(item => item.date === parsedDate);
				if (!existing) {
					total.push({
						date: parsedDate,
						events: [
							{
								_id: current._id,
								company_name: current.company_name,
								event_name: event.name,
								status: calculateStatus(current.on_hold, current.stage, calculateDueDate(current.stage, current.timeline))
							}
						]
					});
				} else {
					existing.events.push({
						_id: current._id,
						company_name: current.company_name,
						event_name: event.name,
						status: calculateStatus(current.on_hold, current.stage, calculateDueDate(current.stage, current.timeline))
					});
				}
			}
		});
		return total;
	}, []);

	return (
		<Component>
			<FormWrapper>
				<Form.Label>Show me upcoming events for the following:</Form.Label>
				<Form.Select value={periodDays} onChange={e => setPeriodDays(e.target.value)}>
					<option value='7'>7 days</option>
					<option value='14'>14 days</option>
					<option value='21'>21 days</option>
					<option value='28'>28 days</option>
				</Form.Select>
			</FormWrapper>
			<EventWrapper>
				{upcomingDates.length > 0 ? (
					upcomingDates.map(date => (
						<DateRow key={date.date}>
							<Date>{date.date}</Date>
							<Events>
								{date.events.map(event => (
									<Event key={event._id}>
										<Status status={event.status} />
										<Details to={`/plan/${event._id}`}>
											<Name>{event.event_name}</Name>
											<Company>{event.company_name}</Company>
										</Details>
									</Event>
								))}
							</Events>
						</DateRow>
					))
				) : (
					<NoEvents>No events during this time period.</NoEvents>
				)}
			</EventWrapper>
		</Component>
	);
};

export default Schedule;
