import { Field } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';

const ProjectDetails = () => {
	return (
		<Row className='my-3'>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>AppFolio Onboarding Contact</Form.Label>
					<Field as={Form.Control} name='appfolio_onboarding_contact' />
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Company Name</Form.Label>
					<Field as={Form.Control} name='company_name' />
				</div>
			</Col>

			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Existing Website URL</Form.Label>
					<Field as={Form.Control} name='existing_website_url' />
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Final Approver</Form.Label>
					<Field as={Form.Control} name='final_approver' />
				</div>
			</Col>

			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label className='font-bold'>Project Type</Form.Label>
					<Field as={Form.Check} className='my-1' type='radio' name='project_type' id='new_site' value='New Site' label='New Site' />
					<Field as={Form.Check} className='my-1' type='radio' name='project_type' id='replacing_existing_site' value='Replacing Existing Site' label='Replacing Existing Site' />
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label className='font-bold'>Billing Date Agreement</Form.Label>
					<p>Once the preview site has been delivered, the site is in a billable stage and the customer will be billed on the 1st of the following month.</p>
					<Form.Check type='checkbox' id='agreement'>
						<Field as={Form.Check.Input} type='checkbox' name='billing_date_agreement' value='Yes, agreed to by customer' />
						<Form.Check.Label>Yes, agreed to by customer</Form.Check.Label>
					</Form.Check>
				</div>
			</Col>
		</Row>
	);
};

export default ProjectDetails;
