import styled from 'styled-components';
import { handleMissingImage } from 'utils/Errors';

const Profile = styled.img`
	border-radius: 50%;
	margin: 0 auto;
`;

const ProfileImage = ({ ...props }) => {
	return <Profile {...props} onError={({ currentTarget }) => handleMissingImage(currentTarget)} loading='lazy' />;
};

export default ProfileImage;
