import { Field } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';

const DomainInfo = () => {
	return (
		<Row className='my-3'>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Domain Name</Form.Label>
					<Field as={Form.Control} name='domain_name' />
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Domain Registrar</Form.Label>
					<Field as={Form.Control} name='domain_registrar' />
				</div>
			</Col>

			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Domain Ownership</Form.Label>
					<Field as='select' className='form-select' name='domain_ownership'>
						<option value=''></option>
						<option value='Client owns directly'>Client owns directly</option>
						<option value='IT contact owns'>IT contact owns</option>
						<option value='AppFolio purchased'>AppFolio purchased</option>
					</Field>
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Is email set up on this domain?</Form.Label>
					<Field as='select' className='form-select' name='is_email_set_up_on_this_domain'>
						<option value=''></option>
						<option value='Yes'>Yes</option>
						<option value='No'>No</option>
						<option value='Not sure'>Not sure</option>
					</Field>
				</div>
			</Col>

			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Registrar Username</Form.Label>
					<Field as={Form.Control} name='registrar_username' />
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label>Registrar Password</Form.Label>
					<Field as={Form.Control} name='registrar_password' />
				</div>
			</Col>
		</Row>
	);
};

export default DomainInfo;
