import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	position: sticky;
	bottom: 0;
	left: 0;
	z-index: 1;
	padding: 1rem 0 2rem;

	button,
	a {
		box-shadow: 0 5px 10px #0001;
	}
`;

const FloatingButtons = ({ children, ...props }) => {
	return <Wrapper {...props}>{children}</Wrapper>;
};

export default FloatingButtons;
