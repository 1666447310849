import { calculateDueDate, calculateStatus } from 'utils/Stages';
import { tidyDateWords } from 'utils/Tidy';
import { CalendarDays } from 'lucide-react';
import { cn } from 'utils/Render';

const DueDate = ({ plan }) => {
	// Destructure props
	const { stage, timeline, on_hold } = { ...plan };

	// Stage calculations
	const dueDate = calculateDueDate(stage, timeline);
	const status = calculateStatus(on_hold, stage, dueDate);

	// Status Map
	const statusMap = {
		'On Time': 'text-success',
		Delayed: 'text-warning',
		Overdue: 'text-danger'
	};

	return (
		stage !== 'Live' && (
			<h4 className={cn('text-2xl font-bold flex items-center gap-2', statusMap[status])}>
				<CalendarDays size={20} />
				<div>{tidyDateWords(dueDate)}</div>
			</h4>
		)
	);
};

export default DueDate;
