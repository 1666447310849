import { useState } from 'react';
import MainWrapper from 'components/shared/MainWrapper';
import PageHeader from 'components/shared/PageHeader';
import { Form, Row, Col, Button } from 'react-bootstrap';
import FloatingButtons from 'components/shared/FloatingButtons';
import { Link, useNavigate } from 'react-router-dom';
import { useLogin } from 'utils/UserContext';
import { useMessage } from 'utils/MessageContext';
import { Helmet } from 'react-helmet-async';
import ObjectID from 'bson-objectid';
import { tidyDatePicker } from 'utils/Tidy';
import { fetcher } from 'utils/Fetch';
import useSWR from 'swr';

const New = () => {
	// Hooks
	const { user } = useLogin();
	const { data: userData } = useSWR(user ? `/api/v1/users/${user._id}` : null);
	const navigate = useNavigate();
	const { setMessage } = useMessage();

	// Initial state
	const initialResponse = {
		company_name: '',
		company_contact: '',
		duda_site_id: '',
		sf_vplus_object_id: '',
		sf_vplus_object_url: '',
		appfolio_onboarding_contact: userData?.email,
		site_type: 'Fixed',
		stage: 'Welcome',
		on_hold: false,
		kick_off_url: userData?.kick_off_url || '',
		revisions_url: userData?.revisions_url || '',
		assets: [
			{ id: ObjectID().toHexString(), name: 'Logo', complete: false },
			{ id: ObjectID().toHexString(), name: 'Design Choice', complete: false },
			{ id: ObjectID().toHexString(), name: 'Business information (phone, email, address)', complete: false },
			{ id: ObjectID().toHexString(), name: 'Project Photos & Descriptions (istockphoto.com)', complete: false },
			{ id: ObjectID().toHexString(), name: 'Performance Metrics (case studies, pitch decks, etc.)', complete: false },
			{ id: ObjectID().toHexString(), name: 'Team Bios & Headshots', complete: false }
		],
		project_start_date: tidyDatePicker(new Date()),
		events: []
	};

	// Component state
	const [formResponse, setFormResponse] = useState(initialResponse);

	// Handle input change
	const handleChange = e => {
		const { value, name } = e.target;
		setFormResponse(prev => ({ ...prev, [name]: value }));
	};

	// Handle form submit
	const handleSubmit = async e => {
		// Prevent page refresh
		e.preventDefault();

		// Submit
		try {
			const data = await fetcher('/api/v1/plans/new', { method: 'POST', body: JSON.stringify(formResponse) });
			navigate(`/plan/${data._id}`);
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error creating a new project plan.'
			});
		}
	};

	return (
		<>
			<Helmet>
				<title>New Project Plan | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading='New Project Plan' subheading='Create a new project plan.' />
			<MainWrapper>
				<Form onSubmit={e => handleSubmit(e)}>
					<h5 className='text-xl font-semibold'>Project Details</h5>
					<Row className='my-3'>
						<Col lg='6'>
							<div className='mb-3'>
								<Form.Label>Company name</Form.Label>
								<Form.Control name='company_name' value={formResponse.company_name} onChange={e => handleChange(e)} required />
							</div>
							<div className='mb-3'>
								<Form.Label>Company contact</Form.Label>
								<Form.Control type='email' name='company_contact' value={formResponse.company_contact} onChange={e => handleChange(e)} required />
							</div>
						</Col>
						<Col lg='6'>
							<div className='mb-3'>
								<Form.Label>Site Type</Form.Label>
								<Form.Select name='site_type' value={formResponse.site_type} onChange={e => handleChange(e)} required>
									<option>Fixed</option>
									<option>Flexible</option>
								</Form.Select>
							</div>
							<div className='mb-3'>
								<Form.Label>Project Start Date</Form.Label>
								<Form.Control type='date' name='project_start_date' value={formResponse.project_start_date} onChange={e => handleChange(e)} required />
							</div>
						</Col>
					</Row>
					<h5 className='text-xl font-semibold'>SalesForce Details</h5>
					<Row className='my-3'>
						<Col lg='6'>
							<div className='mb-3'>
								<Form.Label>V+ Object Name</Form.Label>
								<Form.Control url='text' name='sf_vplus_object_id' value={formResponse.sf_vplus_object_id} onChange={e => handleChange(e)} required />
							</div>
						</Col>
						<Col lg='6'>
							<div className='mb-3'>
								<Form.Label>V+ Object URL</Form.Label>
								<Form.Control type='url' name='sf_vplus_object_url' value={formResponse.sf_vplus_object_url} onChange={e => handleChange(e)} required />
							</div>
						</Col>
					</Row>
					<FloatingButtons>
						<Button type='submit'>
							Create Plan <i className='fas fa-folder-plus'></i>
						</Button>
						<Button as={Link} to='/plans' variant='secondary'>
							Cancel <i className='fas fa-times-circle'></i>
						</Button>
					</FloatingButtons>
				</Form>
			</MainWrapper>
		</>
	);
};

export default New;
