import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import StepIndicator from './StepIndicator';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

// Steps
import Configure from './Steps/1-Configure';
import Generate from './Steps/2-Generate';
import Import from './Steps/3-Import';
import Verify from './Steps/4-Verify';
import Details from './Steps/5-Details';
import Success from './Steps/6-Success';

const Component = styled.div``;

const Form = styled.form``;

const Buttons = styled.div`
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	margin: 4rem 0 0;
`;

const PropertyCollection = () => {
	// Component state
	const [currentStep, setCurrentStep] = useState(1);
	const [csvFields, setCsvFields] = useState([]);
	const [importedCsv, setImportedCsv] = useState([]);
	const [collectionData, setCollectionData] = useState({ site_id: '', fields: [], name: '' });
	const [loading, setLoading] = useState(false);

	// Hooks
	const { setMessage } = useMessage();

	// All steps
	const allSteps = [
		{
			order: 1,
			name: 'Configure csv fields',
			icon: 'cog',
			component: Configure
		},
		{
			order: 2,
			name: 'Generate csv template',
			icon: 'file-csv',
			component: Generate
		},
		{
			order: 3,
			name: 'Import customer csv',
			icon: 'file-import',
			component: Import
		},
		{
			order: 4,
			name: 'Verify data',
			icon: 'database',
			component: Verify
		},
		{
			order: 5,
			name: 'Specify collection details',
			icon: 'sliders-h',
			component: Details
		},
		{
			order: 6,
			name: 'Success',
			icon: 'check-circle',
			component: Success
		}
	];

	// Handlers
	const handleSubmit = async e => {
		setLoading(true);
		e.preventDefault();
		const { name, site_id } = collectionData;
		try {
			await fetcher('/api/v1/collections/new', { method: 'POST', body: JSON.stringify(collectionData) });
			await fetcher('/api/v1/collections/data', { method: 'POST', body: JSON.stringify({ name, site_id, data: importedCsv }) });
			setTimeout(() => setLoading(false), 1000);
			setCurrentStep(6);
		} catch (error) {
			setMessage({ variant: 'danger', text: `There was an error creating this collection - ${error}` });
		} finally {
			setLoading(false);
		}
	};

	// Render
	return (
		<>
			<Helmet>
				<title>New Property Collection | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading={'Property Collections'} subheading={'Create or modify a  collection to generate property pages.'} />
			{loading && <LoadingOverlay />}
			<Component>
				<StepIndicator currentStep={currentStep} allSteps={allSteps} />
				<Form onSubmit={e => handleSubmit(e)}>
					{allSteps.map(step => step.order === currentStep && <step.component key={step.order} csvFields={csvFields} setCsvFields={setCsvFields} importedCsv={importedCsv} setImportedCsv={setImportedCsv} collectionData={collectionData} setCollectionData={setCollectionData} />)}
					<Buttons>
						{currentStep !== 6 && (
							<Button variant='secondary' onClick={() => setCurrentStep(currentStep - 1)} disabled={currentStep === 1}>
								<i className='fas fa-angle-left'></i> Previous
							</Button>
						)}
						{currentStep < allSteps.length - 1 && (
							<Button variant='primary' onClick={() => setCurrentStep(currentStep + 1)} disabled={currentStep === 3 && importedCsv.length === 0}>
								Next <i className='fas fa-angle-right'></i>
							</Button>
						)}
						{currentStep === allSteps.length - 1 && (
							<Button variant='primary' type='submit' disabled={!collectionData.site_id || !collectionData.name}>
								Create collection <i className='fas fa-database'></i>
							</Button>
						)}
					</Buttons>
				</Form>
			</Component>
		</>
	);
};

export default PropertyCollection;
