import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

const Component = styled.div`
	color: var(--bs-danger);
	text-align: center;
	padding: 3rem 1rem;
`;

const Text = styled.div`
	margin: 0;
`;

const Error = () => {
	return (
		<Component>
			<Helmet>
				<title>Error | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<h1 className='text-7xl font-bold mb-2'>
				<i className='fas fa-exclamation-circle'></i> Error
			</h1>
			<Text>Sorry, looks like there was a problem.</Text>
		</Component>
	);
};

export default Error;
