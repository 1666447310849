import ProfileImage from 'components/shared/ProfileImage';

const Designer = ({ designer }) => {
	// Destructure props
	const { email, imageUrl, jobTitle, name, userLocation } = { ...designer };

	return (
		<div className='p-4 space-y-2 text-center'>
			<ProfileImage src={imageUrl} alt={name} width={100} />
			<div className=''>{name}</div>
			<div>
				<div>{jobTitle}</div>
				<div className='text-slate-500'>{userLocation}</div>
				<a href={`mailto:${email}`} className='text-sky-500'>
					{email}
				</a>
			</div>
		</div>
	);
};

export default Designer;
