import { Field } from 'formik';
import { Form } from 'react-bootstrap';

const CompanyInfo = () => {
	return (
		<div className='my-3'>
			<Form.Label>Contact Details</Form.Label>
			<Field as='textarea' className='form-control' rows={7} name='contact_details' />
		</div>
	);
};

export default CompanyInfo;
