import { Field } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';

const DesignAesthetics = () => {
	return (
		<Row className='my-3'>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label className='m-0'>Logo Status</Form.Label>
					<Form.Text className='d-block mb-2 text-muted'>Current status of the logo design</Form.Text>
					<Field as={Form.Select} className='form-control' name='logo_status'>
						<option value=''></option>
						<option value='Customer has provided'>Customer has provided</option>
						<option value='Follow up with customer to provide'>Follow up with customer to provide</option>
						<option value='AppFolio to create'>AppFolio to create</option>
					</Field>
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label className='m-0'>Logo Notes</Form.Label>
					<Form.Text className='d-block mb-2 text-muted'>Font style, color scheme, content, etc.</Form.Text>
					<Field as='textarea' className='form-control' rows={5} name='logo_notes' />
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label className='m-0'>Color scheme</Form.Label>
					<Form.Text className='d-block mb-2 text-muted'>Does the customer have a particular color scheme they would like to use?</Form.Text>
					<Field as={Form.Control} name='color_scheme' />
				</div>
			</Col>
			<Col lg={6}>
				<div className='mb-3'>
					<Form.Label className='m-0'>Photos</Form.Label>
					<Form.Text className='d-block mb-2 text-muted'>What kind of photos does the customer want to use on the home page?</Form.Text>
					<Field as='textarea' className='form-control' rows={5} name='photos' />
				</div>
			</Col>
		</Row>
	);
};

export default DesignAesthetics;
