import GeneralContent from 'components/Briefs/Sections/WebsiteContent/GeneralContent';
import GlobalQuestions from 'components/Briefs/Sections/WebsiteContent/GlobalQuestions';
import GranularQuestions from 'components/Briefs/Sections/WebsiteContent/GranularQuestions';
import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';
import { Toggle } from 'components/Briefs/Shared/Components';

const Section = styled.section`
	margin: 1rem 0;
`;

const WebsiteContent = () => {
	const sections = [
		{
			name: 'General Content',
			component: GeneralContent
		},
		{
			name: 'Global Questions',
			component: GlobalQuestions
		},
		{
			name: 'Granular Questions',
			component: GranularQuestions
		}
	];

	return (
		<Accordion defaultActiveKey={1}>
			{sections.map((section, i) => (
				<Section key={i + 1}>
					<Toggle eventKey={i + 1}>
						{section.name} <i className='fas fa-angle-down text-muted ms-1'></i>
					</Toggle>
					<Accordion.Collapse eventKey={i + 1}>
						<section.component />
					</Accordion.Collapse>
				</Section>
			))}
		</Accordion>
	);
};

export default WebsiteContent;
