import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import { Helmet } from 'react-helmet-async';
import MainWrapper from 'components/shared/MainWrapper';
import { Typeahead } from 'react-bootstrap-typeahead';
import useSWR from 'swr';
import { useNavigate, Link } from 'react-router-dom';
import AdminTable from './AdminTable';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import LoadingOverlay from 'components/shared/LoadingOverlay';

const NoPlans = styled.div`
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
	padding: 2rem 1rem;
	background: white;
	text-align: center;
	margin: 2rem auto;
`;

const Admin = () => {
	// Component state
	const [hideLivePlans, setHideLivePlans] = useState(true);
	const [plans, setPlans] = useState([]);

	// Hooks
	const { data: planData } = useSWR('/api/v1/plans/all');
	const navigate = useNavigate();

	// Effects
	useEffect(() => {
		setPlans(planData);
	}, [planData]);

	useEffect(() => {
		if (planData) {
			hideLivePlans ? setPlans(planData.filter(plan => plan.stage !== 'Live')) : setPlans(planData);
		}
	}, [planData, hideLivePlans]);

	// Go to plan
	const goToPlan = e => {
		const selected = e[0];
		const selectedSite = plans.find(plan => plan._id === selected._id);
		navigate(`/plan/${selectedSite._id}`);
	};

	return (
		<>
			<Helmet>
				<title>Admin Panel | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading={'Admin Panel'} subheading={'View all user project plans.'} />
			<MainWrapper>
				{plans ? (
					plans.length > 0 ? (
						<>
							<Typeahead className='border-0 shadow-sm' id='basic-typeahead-single' labelKey='name' onChange={e => goToPlan(e)} options={plans.map(plan => ({ _id: plan._id, name: plan.company_name }))} placeholder='Search for a company...' minLength={2} />
							<Form.Switch id='hide-live-plans' label='Hide Live Project Plans' className='mt-4' checked={hideLivePlans} onChange={() => setHideLivePlans(!hideLivePlans)} />
							<AdminTable plans={plans} />
						</>
					) : (
						<NoPlans>
							<div>No plans to display.</div>
							<Button className='mt-2' as={Link} to={'/plan/new'}>
								Create a New Project Plan <i className='fas fa-folder-plus'></i>
							</Button>
						</NoPlans>
					)
				) : (
					<LoadingOverlay />
				)}
			</MainWrapper>
		</>
	);
};

export default Admin;
