import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { useLogin } from 'utils/UserContext';

// Styled components
const Component = styled.aside`
	background: var(--bs-dark);
	color: white;
	position: relative;
	overflow: hidden;
	grid-area: sidebar;

	@media (max-width: 767px) {
		position: sticky;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 1;
		display: flex;
		justify-content: center;
	}
`;

const Icon = styled.div`
	width: 2rem;
	font-size: 1.25rem;
	text-align: center;
	color: var(--bs-primary);
	transition: 0.1s;
`;

const Text = styled.div`
	transition: 0.1s;

	@media (max-width: 767px) {
		display: none;
	}
`;

const SidebarLink = styled(NavLink)`
	color: white;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	height: 4rem;
	text-decoration: none !important;
	gap: 1rem;
	transition: 0.1s;
	position: relative;

	&:hover,
	&:focus {
		${Icon}, ${Text} {
			color: var(--Gold);
		}
	}

	&.active {
		background: var(--bs-primary);

		${Icon}, ${Text} {
			color: white;
		}
	}
`;

const Divider = styled.div`
	font-size: 0.8rem;
	border-bottom: 1px solid;
	padding: 0.75rem;
	color: #fff9;

	@media (max-width: 767px) {
		display: none;
	}
`;

const Sidebar = () => {
	// Hooks
	const { user } = useLogin();

	return (
		<Component>
			<SidebarLink to='/plans'>
				<Icon>
					<i className='fas fa-stream'></i>
				</Icon>
				<Text>My Project Plans</Text>
			</SidebarLink>
			<SidebarLink to='/plan/new'>
				<Icon>
					<i className='fas fa-folder-plus'></i>
				</Icon>
				<Text>New Project Plan</Text>
			</SidebarLink>
			<SidebarLink to='/briefs'>
				<Icon>
					<i className='fas fa-clipboard-list'></i>
				</Icon>
				<Text>My Creative Briefs</Text>
			</SidebarLink>
			<SidebarLink to='/collections/new'>
				<Icon>
					<i className='fas fa-database'></i>
				</Icon>
				<Text>Collections</Text>
			</SidebarLink>
			<Divider>Admin</Divider>
			<SidebarLink to='/edit/designer'>
				<Icon>
					<i className='fas fa-user-edit'></i>
				</Icon>
				<Text>Edit Designer Info</Text>
			</SidebarLink>
			{user.isAdmin && (
				<SidebarLink to='/admin'>
					<Icon>
						<i className='fas fa-user-shield'></i>
					</Icon>
					<Text>Admin Panel</Text>
				</SidebarLink>
			)}
		</Component>
	);
};

export default Sidebar;
