import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

const PlanStatus = ({ on_hold, stage, dueDate, showDelay }) => {
	// Component level state
	const [currentDate, setCurrentDate] = useState('');
	const [status, setStatus] = useState('');
	const [delayText, setDelayText] = useState('');

	// Set today's date
	useEffect(() => {
		setCurrentDate(dayjs().format('MM/DD/YYYY'));
	}, []);

	// Define project status
	useEffect(() => {
		const status = dayjs(dueDate).diff(currentDate, 'day');
		if (Math.sign(status) === 1 || Math.sign(status) === 0) {
			setStatus('ok');
		} else {
			const delay = Math.abs(status);
			setDelayText(delay);
			if (delay < 7) {
				setStatus('delayed');
			} else {
				setStatus('overdue');
			}
		}
	}, [dueDate, currentDate]);

	if (on_hold) {
		return (
			<div>
				<i className='fas fa-times-circle text-danger me-1'></i> On Hold
			</div>
		);
	}

	if (stage === 'Live') {
		return (
			<div>
				<i className='fas fa-check-circle text-primary me-1'></i> Complete
			</div>
		);
	}

	if (status) {
		if (status === 'ok') {
			return (
				<div>
					<i className='fas fa-check-circle text-success me-1'></i> On Time
				</div>
			);
		}
		if (status === 'delayed') {
			return (
				<div>
					<i className='fas fa-exclamation-circle text-warning me-1'></i> Delayed{showDelay && ` by ${delayText} days`}
				</div>
			);
		}
		if (status === 'overdue') {
			return (
				<div>
					<i className='fas fa-exclamation-circle text-danger me-1'></i> Overdue{showDelay && ` by ${delayText} days`}
				</div>
			);
		}
	} else return null;
};

export default PlanStatus;
