import { Field } from 'formik';
import { Form } from 'react-bootstrap';

const GlobalQuestions = () => {
	return (
		<>
			<div className='mb-3'>
				<Form.Label>How long have you been in business? How did you get started?</Form.Label>
				<Field as='textarea' className='form-control' name='how_long_have_you_been_in_business' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label>What are your values or mission statement? What sets you apart from other Investment Managers?</Form.Label>
				<Field as='textarea' className='form-control' name='what_are_your_values_or_mission_statement' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label>How do you currently attract new investors? Do you have a pitch deck you show to prospective clients? Would you mind walking me through the highlights? What do you want to bring over to your new website?</Form.Label>
				<Field as='textarea' className='form-control' name='how_do_you_currently_attract_new_investors' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label>What do you like (or dislike) about your current website? What content do you want me to pull over from your existing site? What do you want to leave off?</Form.Label>
				<Field as='textarea' className='form-control' name='what_do_you_like_or_dislike_about_your_current_website' rows={5} />
			</div>
			<div className='mb-3'>
				<Form.Label>Give me an example of a website you really like. What do you like about it?</Form.Label>
				<Field as='textarea' className='form-control' name='give_me_an_example_of_a_website_you_really_like' rows={5} />
			</div>
		</>
	);
};

export default GlobalQuestions;
