import styled from 'styled-components';

import { tidyDateWords } from 'utils/Tidy';
import { Link } from 'react-router-dom';

const Table = styled.div`
	margin: 2rem auto;
	border-radius: 0.5rem;
	overflow: auto;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
`;

const TableCell = styled.div`
	padding: 0.5rem 0.75rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: var(--darkest);
	transition: 0.1s;
	background: white;
`;

const TableHead = styled.div`
	${TableCell} {
		background: var(--darkest);
		color: white;
	}
`;

const TableBody = styled.div``;

const HeaderRow = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(250px, 2fr)) repeat(3, minmax(125px, 1fr));
`;

const TableRow = styled(Link)`
	display: grid;
	grid-template-columns: repeat(2, minmax(250px, 2fr)) repeat(3, minmax(125px, 1fr));
	text-decoration: none !important;

	&:hover ${TableCell}, &:focus ${TableCell} {
		background: var(--Gold);
		color: white;

		i {
			color: white;
		}
	}
`;

const NoPlans = styled(TableCell)`
	text-align: center;
	background: white;
`;

const Briefs = ({ briefs }) => {
	return (
		<Table>
			<TableHead>
				<HeaderRow>
					<TableCell>Company Name</TableCell>
					<TableCell>Project Type</TableCell>
					<TableCell>Website Type</TableCell>
					<TableCell>Last Saved</TableCell>
					<TableCell>
						Created <i className='fas fa-angle-down text-muted ms-1'></i>
					</TableCell>
				</HeaderRow>
			</TableHead>
			{briefs.length > 0 ? (
				<TableBody>
					{briefs.map(brief => (
						<TableRow to={`/brief/${brief._id}`} key={brief._id}>
							<TableCell>{brief.company_name}</TableCell>
							<TableCell>{brief.creative_brief?.project_type}</TableCell>
							<TableCell>{brief.site_type}</TableCell>
							<TableCell>{tidyDateWords(brief.creative_brief?.last_saved)}</TableCell>
							<TableCell>{tidyDateWords(brief.createdAt)}</TableCell>
						</TableRow>
					))}
				</TableBody>
			) : (
				<NoPlans>No briefs to display.</NoPlans>
			)}
		</Table>
	);
};

export default Briefs;
